<template>
    <v-container fluid>
        <v-form>
            <v-text-field v-model="anbieter_model.name" label="Name" required></v-text-field>
            <v-textarea v-model="anbieter_model.address" label="Adresse"></v-textarea>
            <v-text-field v-model="anbieter_model.logo" label="Logo URL"></v-text-field>
            <v-spacer></v-spacer>
            <v-btn block @click="saveAnbieter" color="primary">Speichern</v-btn>
        </v-form>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Anbieter from '@/store/models/Anbieter';

    export default {
        name: "AnbieterNew",
        computed: {
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcAccessToken',
                'oidcError'
            ])
        },
        data() {
            return {
                anbieter_model: new Anbieter(),
                dialog: null,
                save_counter: 0
            }
        },
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
            ]),
            async saveAnbieter() {
                if (!this.oidcIsAuthenticated) {
                    this.save_counter++
                    this.authenticateOidcSilent().then(
                        () => {
                            if (this.save_counter < 3) {
                                this.saveAnbieter()
                            }
                        }
                    )
                    return
                }
                const model = this.$store.$db().model('anbieter')
                await model.api().post(`/api/v1/anbieter/`, this.anbieter_model, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                })
                this.save_counter = 0
                await this.$router.push({path: '/anbieter/'})
            }
        },
    }
</script>

<style scoped>

</style>
