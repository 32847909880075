<template>
    <AnbieterNew></AnbieterNew>
</template>

<script>
    import AnbieterNew from '@/components/AnbieterNew';
    export default {
        name: "NewAnbieter",
        components: {AnbieterNew},
    }
</script>

<style scoped>

</style>
